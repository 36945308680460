<template>
  <div class="uk-form-stacked">
    <div
      class="uk-child-width-expand@s uk-grid-medium"
      uk-grid
    >
      <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l">
        <div
          class="uk-child-width-expand@s uk-grid-medium"
          uk-grid
        >
          <div class="uk-inline uk-width-1-1">
            <label class="uk-form-label">
              EVC Code
            </label>
            <div class="uk-form-controls">
              <input
                v-model="formExportHub.evc_code"
                v-validate="'required|max:50'"
                name="evc_code"
                class="uk-input"
                type="text"
                autocomplete="off"
                placeholder="Enter EVC Code"
                :class="{'uk-form-danger': errors.has('evc_code')}"
              >
            </div>
            <span
              v-show="errors.has('evc_code')"
              class="uk-text-small uk-text-danger"
            >{{ errors.first('evc_code') }}</span>
          </div>
          <div class="uk-inline uk-width-1-1">
            <label class="uk-form-label">
              Region
            </label>
            <div class="uk-form-controls">
              <multiselect 
                v-model="regionChoose" 
                v-validate="'required'"
                label="name" 
                name="region" 
                track-by="id" 
                placeholder="Select Region" 
                open-direction="bottom" 
                :options="listRegions" 
                :searchable="true"
                :max-height="200"
                :show-no-results="true"
              >
                <span slot="noResult">Oops! Data tidak ditemukan.</span>
              </multiselect>
            </div>
            <span
              v-show="errors.has('region')"
              class="uk-text-small uk-text-danger"
            >{{ errors.first('region') }}</span>
          </div>
          <div class="uk-inline uk-width-1-1">
            <label class="uk-form-label">
              Latitude
            </label>
            <div class="uk-form-controls">
              <input
                v-model="formExportHub.latitude"
                v-validate="'required|max:100'"
                name="latitude"
                class="uk-input"
                type="text"
                autocomplete="off"
                placeholder="Enter Latitude"
                :class="{'uk-form-danger': errors.has('latitude')}"
              >
            </div>
            <span
              v-show="errors.has('latitude')"
              class="uk-text-small uk-text-danger"
            >{{ errors.first('latitude') }}</span>
          </div>
        </div>
      </div>
          
      <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l">
        <div
          class="uk-child-width-expand@s uk-grid-medium"
          uk-grid
        >
          <div class="uk-inline uk-width-1-1">
            <label class="uk-form-label">
              Export Hub Name
            </label>
            <div class="uk-form-controls">
              <input
                v-model="formExportHub.name"
                v-validate="'required|max:50'"
                name="export_hub_name"
                class="uk-input"
                type="text"
                autocomplete="off"
                placeholder="Enter Export Hub Name"
                :class="{'uk-form-danger': errors.has('export_hub_name')}"
              >
            </div>
            <span
              v-show="errors.has('export_hub_name')"
              class="uk-text-small uk-text-danger"
            >{{ errors.first('export_hub_name') }}</span>
          </div>

          <div class="uk-inline uk-width-1-1">
            <label class="uk-form-label">
              Address
            </label>
            <div class="uk-form-controls">
              <input
                v-model="formExportHub.address"
                v-validate="'required|max:100'"
                name="address"
                class="uk-input"
                type="text"
                autocomplete="off"
                placeholder="Enter Address"
                :class="{'uk-form-danger': errors.has('address')}"
              >
            </div>
            <span
              v-show="errors.has('address')"
              class="uk-text-small uk-text-danger"
            >{{ errors.first('address') }}</span>
          </div>

          <div class="uk-inline uk-width-1-1">
            <label class="uk-form-label">
              Longitude
            </label>
            <div class="uk-form-controls">
              <input
                v-model="formExportHub.longitude"
                v-validate="'required|max:100'"
                name="longitude"
                class="uk-input"
                type="text"
                autocomplete="off"
                placeholder="Enter Longitude"
                :class="{'uk-form-danger': errors.has('longitude')}"
              >
            </div>
            <span
              v-show="errors.has('longitude')"
              class="uk-text-small uk-text-danger"
            >{{ errors.first('longitude') }}</span>
          </div>
        </div>
      </div>
    </div>
                
    <div class="uk-flex uk-flex-right uk-margin-medium-top">
      <button
        class="uk-button soft-green uk-margin-right"
        @click="showCancelConfirmModal"
      >
        Cancel
      </button>
      <button
        class="uk-button green"
        :class="isCanAccessUser('add', 'EXPORT HUB') ? '' : 'disabled'"
        :disabled="!isCanAccessUser('add', 'EXPORT HUB')"
        @click="showSaveConfirmModal"
      >
        Save
      </button>
    </div>
  </div>
</template>

<script>
import { isCanAccess } from '@/utils/auth'

export default {
  props: {
    listRegions: {
      required: true,
      type: Array
    },
    setToggleModalAddEditDiscard: {
      required: true,
      type: Function
    },
    getToggleModalAddEditDiscard: {
      required: true,
      type: Object
    },
    setDataModalAddEdit: {
      required: true,
      type: Function
    }
  },
  data() {
    return{
      formExportHub: {
        evc_code: '',
        name: '',
        region_id: '',
        latitude: '',
        longitude: '',
        address: ''
      },
      regionChoose: ''
    }
  },
  methods: {
    isCanAccessUser(type, menu) {
      return isCanAccess(type, menu)
    },
    showCancelConfirmModal() {
      this.setToggleModalAddEditDiscard({
        targetModals: true,
        title: 'Cancel Confirmation', 
        description: 'All data will be lost if you leave. Are you sure want to leave?',
        customTitleBtnOk: 'Yes',
        customTitleBtnCancel: 'No',
        type: 'discard',
        route: 'ExportHub',
        loadingTable: false,
        loadingPopUp: false
      })
    },
    showSaveConfirmModal() {
      this.formExportHub.region_id = this.regionChoose ? this.regionChoose.id : ''
      this.$validator.validateAll().then((success) => {
        if (success) {
          if (this.$validator.errors.any()) return
          this.setDataModalAddEdit(this.formExportHub)
          this.setToggleModalAddEditDiscard({
            targetModals: true,
            title: 'Save Confirmation', 
            description: 'Are you sure want to save this data?',
            customTitleBtnOk: 'Save',
            customTitleBtnCancel: 'Cancel',
            type: 'save',
            loadingTable: false,
            loadingPopUp: false
          })
        }
      })
    }
  }
}
</script>
